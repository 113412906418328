export default [
  {
    label: "Identitas Peserta Didik",
    link: "/",
    icon: ["fas", "address-card"],
    isExactActive: true,
  },
  {
    label: "Orang Tua & Mahrom",
    link: "/ortuwali",
    icon: ["fas", "users-cog"],
  },
  {
    label: "Upload Berkas",
    link: "/berkas",
    icon: ["fas", "image"],
  },
  {
    label: "Pembayaran",
    link: "/pembayaran",
    icon: ["fas", "money-check-alt"],
  },
  {
    label: "Cetak Pendaftaran",
    link: "/cetak",
    icon: ["fas", "print"],
  },
  {
    label: "Angket",
    link: "/angket",
    icon: ["fas", "file-alt"],
  },
  {
    label: "Pengaturan Akun",
    link: "/akun",
    icon: ["fas", "user-circle"],
  },
  {
    label: "Keluar",
    link: "/logout",
    icon: ["fas", "sign-out-alt"],
  },
];
