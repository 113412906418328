<!--suppress ALL -->
<template>
  <div>
    <main class="header-mobile-dashboard d-block d-md-none d-lg-none d-xl-none">
      <div class="container">
        <div class="d-md-flex flex-row-reverse">
          <div class="flex-fill">
            <router-link
              to="/logout"
              class="btn-logout-mobile btn btn-danger btn-sm font-weight-light text-light"
              >Keluar</router-link
            >
          </div>
          <div class="flex-fill">
            <div class="user-info">
              <img
                v-if="biodata.fotodiri"
                v-auth-image="biodatas.fotodiri.medium"
                alt="Foto Diri"
                class="user-picture"
              />
              <p
                class="merriweather name mb-1"
                v-text="biodata.nama_lengkap"
              ></p>
              <p class="font-weight-light" v-if="biodata.akun_pendaftar">
                <span
                  >No. Registrasi:
                  <h6>
                    <span
                      v-text="biodata.akun_pendaftar.no_registrasi"
                      class="badge badge-secondary"
                    ></span></h6
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Bottom Navbar -->
    <nav
      class="navbar navbar-expand fixed-bottom d-md-none d-lg-none d-xl-none"
    >
      <ul class="navbar-nav nav-justified w-100">
        <template v-for="(node, indexRoot) in MenuSchema">
          <template v-if="node.labelHeading">
            <template v-if="node.children">
              <!-- MENU ITEM support nested (recursive) -->
              <menu-item
                v-for="(child, index) in node.children"
                :key="index"
                :item="child"
              />
            </template>
          </template>

          <!-- MENU ITEM support nested (recursive) -->
          <menu-item v-else :item="node" :key="`${indexRoot}-${node.label}`" />
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItemMob";
import MenuSchema from "@/router/menuSchema";

export default {
  name: "MobileNav",
  props: ["biodatas"],

  data() {
    return {
      biodata: {},
    };
  },

  components: {
    MenuItem,
  },

  watch: {
    biodatas: {
      handler() {
        this.biodata = this.biodatas;
      },
      deep: true,
    },
  },

  created() {
    this.MenuSchema = MenuSchema;
    this.biodata = this.biodatas;
  },
};
</script>

<style lang="scss">
.header-mobile-dashboard {
  overflow: hidden;
  background: linear-gradient(to right, #00b09b, #96c93d);
  border-bottom: 1px solid #f0e3e1;
  color: #ffffff;
  padding: 24px;
}
.header-mobile-dashboard .user-picture {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 14px;
}
.header-mobile-dashboard .user-info {
  float: left;
  width: 200px;
  overflow: hidden;
}
.header-mobile-dashboard .user-info p {
  margin: 0;
}
.header-mobile-dashboard .user-info .name {
  font-size: 18px;
  margin-top: 6px;
}
.btn-logout-mobile {
  float: right;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: none;
}
@media (max-width: 320px) {
  .btn-logout-mobile {
    margin-top: 6px;
  }
}

.navbar {
  overflow: auto;
  white-space: nowrap;
  background-color: #272727;

  .nav-link {
    color: #a5a5a5;
    font-size: 0.875rem;
  }
}
</style>
