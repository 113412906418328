<template>
  <router-link
    :to="item.link"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
    :exact="item.isExactActive"
  >
    <li class="nav-item">
      <a
        :href="isActive ? '#' : href"
        :class="[
          'nav-link text-center',
          isActive ? 'router-link-active' : null,
          isExactActive ? 'router-link-exact-active' : null,
          route.path === $route.path ? 'disabled' : null,
        ]"
        @click="navigate"
      >
        <fa-icon
          v-if="item.icon"
          :icon="item.icon"
          class="align-middle"
        ></fa-icon>
        <span class="small labelRapi d-block">{{ item.label }}</span>
      </a>

      <!-- NESTED SUB-MENU -->
      <ul
        v-if="item.children && item.children.length"
        class="nav flex-column ml-3"
      >
        <!-- RECURSIVE COMPONENT -->
        <menu-item
          v-for="(child, id) in item.children"
          :key="id"
          :item="child"
        />
      </ul>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "MenuItemMob",

  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
span.labelRapi {
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar .nav-link.router-link-active {
  color: #f0ae19;
}
</style>
